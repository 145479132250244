import React from "react"
// import styled, { css } from "styled-components"
import { Box, Container, Flex } from "theme-ui"
import get from "lodash.get"
import Img from 'gatsby-image'


export function ContentWithImage({ data, html }) {
  // const centered = data.center ? data.center : false

  const align = data.align === null ? ['row'] : ['row-reverse'];
  
  const paddingLeft = data.align === null ? 0 : 10;
  const paddingRight = data.align === null ? 10 : 0;

  const background = data.background_color !== null ? { backgroundColor: data.background_color, margin: '24px 0', padding: '24px 0' } : false;
  const centerContent = background !== false ? { alignItems: 'center' } : {};
  const fontColor = data.font_color !== null ? { color: data.font_color } : {};

  return (
    <Box as="section" sx={background}>  
      <Container className="content-image__container"
        py={6}
        px={4}
        sx={{ 
          p: { fontSize: 1, lineHeight: 1.2 }, 
          h2: { fontSize: 3, fontWeight: '800'}, 
          'h2:first-of-type': {
            marginTop: 0,
          }, 
        }}>
        <Flex sx={{flexDirection: align, ...centerContent, flexWrap: 'wrap'}}>
          <Box className="content-image-block__content"
            pl={[0,paddingLeft]}
            pr={paddingRight}
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          sx={{flex: '1 66%', ...fontColor,
            'h2:not(:first-of-type)': {
              marginTop: '40px'
            }
          }}
          >
          </Box>
          <Box
            sx={{flex: '1 33%', minWidth: '200px'}}
          >
            { data.image !== undefined && (
              <Img fluid={data.image.childImageSharp.fluid} />
            )}        
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export function Content({children, html}) {

  return (
    <Box as="section"
      sx={{
        figure: {
          marginX: 0,

          '& > img': {
            width: '100%',
            height: 'auto'
          }
        }
      }}>  
      <Container className="content__container"
        px={[0,4]}
        py={4}
        sx={{ 
          p: { fontSize: 1 },
        }}>
        { typeof html !== "undefined" ? (
          <Box mt={8} mb={0} className="testClass"
            sx={{
              'h2:first-of-type': {
                marginTop: 8
              }
            }}
            dangerouslySetInnerHTML={{
              __html: html,
          }}/>
        ) : (
          <Box my={8}>
            {children}
          </Box>
        ) }

      </Container>
    </Box>
  )
}

export function BlogContent({children, html}) {

  return (
    <Box as="section" className="blogContentBlock"
      sx={{
        figure: {
          marginX: 0,
          marginY: [4, 8],

          '& > img': {
            width: '100%',
            height: 'auto'
          }
        }
      }}>  
      <Container className="content__container"
        px={[0,4]}
        py={4}
        sx={{ 
          p: { 
            fontSize: 1,
            maxWidth: '960px',
            marginX: 'auto',
            paddingX: '16px'
         },
          h2: {
            maxWidth: '960px',
            marginX: 'auto',
            marginTop: '60px',
            paddingX: '16px',
            letterSpacing: '0.1em',
            fontSize: 3
          },
          h3: {
            maxWidth: '960px',
            marginX: 'auto',
            paddingX: '16px',
            marginTop: '40px'
          },
          ol: {
            maxWidth: '960px',
            marginX: 'auto',
            paddingX: '32px'
          },
          li: {
            marginY: '8px'
          }
        }}>
        { typeof html !== "undefined" ? (
          <Box mt={8} mb={0} className="testClass"
            sx={{
              'h2:first-of-type': {
                marginTop: 8
              }
            }}
            dangerouslySetInnerHTML={{
              __html: html,
          }}/>
        ) : (
          <Box my={8}>
            {children}
          </Box>
        ) }

      </Container>
    </Box>
  )
}

// const StyledContent = styled.div`
//   ${props =>
//     props.center &&
//     css`
//       text-align: center;
//     `};
// `


export const ContentBlock = {
  label: "Content",
  name: "content",
  key: "test",
  defaultItem: {
    content: "",
    center: false,
  },
  fields: [
    { name: "content", label: "Content", component: "markdown" },
    { name: "center", label: "Center", component: "toggle" },
  ],
}

export const ContentWithImageBlock = {
  label: "Content with Image",
  name: "content_with_image",
  key: "test",
  defaultItem: {
    content: "",
    center: false,
  },
  fields: [
    { name: "content", label: "Content", component: "markdown" },
   
    { name: "align", label: "Image Alignment", options: ["Right", "Left"], component: "select"},
    {
      name: 'background_color',
      component: 'color',
      label: 'Background Color',
      description: 'Edit the section background color here',
      colorFormat: 'hex',
      colors: ['#58595B'],
    },
    {
      name: 'font_color',
      component: 'color',
      label: 'Font Color',
      description: 'Edit the section font color here',
      colorFormat: 'hex',
      colors: ['#FFF', '#000'],
    },
    {
      label: "Image",
      name: "image",
      component: "image",
      parse: filename => `../images/${filename}`,
      uploadDir: () => `/content/images/`,
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace("rawJson.", "")
        const imageNode = get(formValues, pathName)
        if (!imageNode || !imageNode.childImageSharp) return ""
        return imageNode.childImageSharp.fluid.src
      },
    },
  ],
}
