import React from "react";
import { Styled, Flex, Box,} from "theme-ui";
import { Container } from "./item";
import styled from "styled-components";

const DateArea = styled.span`
  color: #F7941D;
`

const BlogIntro = ({title, date}) => (

  <Box>
    <Container>
      <Flex sx={{flexDirection: 'column', alignItems: 'center', maxWidth: '800px', margin: '0 auto', textAlign: 'center', marginTop: '150px'}}>
        <DateArea pY={0}>{ date }</DateArea>
        <Styled.h1>{title}</Styled.h1>
      </Flex>
    </Container>
  </Box>
)

export default BlogIntro;