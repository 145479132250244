/* @jsx jsx */

import React from "react"
import { graphql } from "gatsby"
import { Styled, Flex, Box, Image, jsx } from "theme-ui" //eslint-disable-line no-unused-vars
import { Container } from '../components/item'
import styled from '@emotion/styled'

import contentParser from 'gatsby-wpgraphql-inline-images';


import ContactForm from '../components/ContactForm'
import BgImage from '../components/BgImage'
import GiantCheck from '../images/icons/giant-check.svg'
import Logo from '../images/icons/nexprotek-logo.svg'

// import { Paper } from "../components/style"
// import { Form, FormBlock } from "../blocks/form"
// import { Title, TitleBlock } from "../blocks/title"
// import { Image, ImageBlock } from "../blocks/image"
// import {Content,  ContentWithImage, ContentBlock, ContentWithImageBlock } from "../blocks/content"
import { BlogContent } from '../blocks/content'
// import { PageLayout } from "../components/pageLayout"

// import { useLocalJsonForm } from "gatsby-tinacms-json"
import Layout from "../components/layout"
// import BasicHero from "../components/BasicHero"
import BlogIntro from "../components/BlogIntro"
import SEO from "../components/seo"

const StyledGiantCheck = styled(GiantCheck)`
  height: 315px;
  width: 315px;
  margin-bottom: 80px;
`

const StyledLogo = styled(Logo)`
  position: absolute;
  left: 10px;
  bottom: 10px;
  width: 300px;
  height: auto;
`

export default function BlogPost({ data, pageContext }) {
  const { title, content, date, image, meta } = pageContext;

  const wordPressUrl = "http://dev-nextdura.pantheonsite.io/";
  const uploadsUrl = "http://dev-nextdura.pantheonsite.io/wp-content/uploads/";

  return (
    <Layout reverseNav>
      <SEO
        title={ meta.title || null }
        description={ meta.desc || null }
      />

      <BlogIntro title={title} date={date} />

      {/* <BasicHero 
        // fluid={page.hero.image.childImageSharp.fluid}  
        title={"Man Welding Hero Image"} 
        heroTitle={title}
        adjacentSectionColor={'white'}
      /> */}
      <Box sx={{position: 'relative'}}>
        <Image sx={{width: '100%', display: 'block'}} src={image} />
        <StyledLogo />
      </Box>


      <BlogContent
        children={contentParser({ content }, { wordPressUrl, uploadsUrl })}
      />

      <Box as="section">
        <Container py={[8,13]}>
          <Box sx={{margin: '0 auto', maxWidth: ['unset', '710px']}}>
            <Styled.h3>Contact Us</Styled.h3>
            <ContactForm name="Blog Post Contact" minimal />
          </Box>
        </Container>

        <BgImage
          fluid={data.abstractTwo.childImageSharp.fluid}
          title="abstract design"
          height="auto"   
        >
          <Flex sx={{flexDirection: 'column', alignItems: 'center', maxWidth: '700px', margin: '0 auto', textAlign: 'center', paddingY: '100px'}}>
            <StyledGiantCheck />
            <Styled.h1 as="h4" sx={{variant: 'text.caps'}}>Value</Styled.h1>
            <Styled.h3 as="p" sx={{lineHeight: '1.3'}}>We guarantee outstanding products, competitive pricing, and 100% customer satisfaction.  </Styled.h3>
          </Flex>
        </BgImage>

      </Box>
    </Layout>
  )
}

export const useQuery = graphql`
  query {
    abstractTwo: file(relativePath: { eq: "abstract-background-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
